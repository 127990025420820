<script>
import Layout from "@/router/layouts/main";
import { api } from "@/state/services";

export default {
  locales: {
    en: {},
    pt: {
      "We apologize, we are unable to retrieve information at this time. Please try again later.":
        "Pedimos desculpas, não podemos recuperar informações no momento. Por favor, tente novamente mais tarde.",
      "No records found.": "Nenhum registro encontrado.",
    },
    es: {
      "We apologize, we are unable to retrieve information at this time. Please try again later.":
        "Nos disculpamos, no podemos recuperar información en este momento. Por favor, inténtelo de nuevo más tarde.",
      "No records found.": "No se encontraron registros.",
    },
  },
  components: { Layout },
  data() {
    return {
      simulation: null,
      loading: false,
    };
  },
  methods: {
    getSimulation() {
      api
        .get("energy/simulations/" + this.$route.params.id)
        .then((response) => {
          if (response.data.status == "success") {
            this.simulation = response.data.simulation;
          } else {
            this.$router.push("/energy/simulations");
          }
        })
        .catch((error) => {
          if (error) {
            this.$router.push("/energy/simulations");
          }
        });
    },
    createContract() {
      this.loading = true;

      api
        .post("energy/contracts", {
          id: this.$route.params.id
        })
        .then((response) => {
          if (response.data.status == "success") {
            this.$router.push("/energy/contracts/" + response.data.contract.id);
          }
        })
        .catch((error) => {
          if (error) {
            this.loading = false;
          }
        });
    }
  },
  mounted() {
    this.getSimulation();
  },
};
</script>

<template>
  <Layout>
    <div class="row">
      <div class="col-12">
        <div class="page-title-box d-flex justify-content-between">
          <h4 class="mb-0 font-size-18">Simulação</h4>
          <div class="page-title-right"></div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div v-if="simulation && simulation.client && simulation.invoice">
              <template v-if="simulation.client.type == 'natural'">
                <div class="divisor mb-3">
                  <span>Dados Pessoais</span>
                </div>
                <div class="mb-3 text-center">
                  <strong>{{ simulation.client.name }}</strong
                  ><br />
                  {{ simulation.client.cellphone }}<br />
                  {{ simulation.client.email }}
                </div>
              </template>
              <template v-else>
                <div class="divisor mb-3">
                  <span>Dados da Empresa</span>
                </div>
                <div class="mb-3 text-center">
                  <strong>{{ simulation.client.legal_name }}</strong>
                </div>

                <div class="divisor mt-5 mb-3">
                  <span>Dados do Representante</span>
                </div>
                <div class="mb-3 text-center">
                  <strong>{{ simulation.client_name }}</strong
                  ><br />
                  {{ simulation.client.cellphone }}<br />
                  {{ simulation.client.email }}
                </div>
              </template>

              <div class="divisor mt-5 mb-3">
                <span>Endereço</span>
              </div>
              <div class="mb-3 text-center">
                {{ simulation.client.address }}, {{ simulation.client.number }}<br />
                {{ simulation.client.district }}, {{ simulation.client.city }}/{{ simulation.client.state
                }}<br />
                {{ simulation.client.zipcode }}
              </div>

              <div class="divisor mt-5 mb-3">
                <span>Dados de Consumo</span>
              </div>

              <div class="row justify-content-center text-center">
                <div class="col-6 col-md-3 mb-3">
                  <span
                    class="bg-soft-light rounded px-2 font-size-11 text-uppercase"
                    >Tipo de Ligação</span
                  ><br />
                  {{ simulation.invoice.type }}
                </div>
                <div class="col-6 col-md-3 mb-3">
                  <span
                    class="bg-soft-light rounded px-2 font-size-11 text-uppercase"
                    >Concessionária</span
                  ><br />
                  {{ simulation.invoice.company }}
                </div>
                <div class="col-6 col-md-3 mb-3">
                  <span
                    class="bg-soft-light rounded px-2 font-size-11 text-uppercase"
                    >Desconto</span
                  ><br />
                  {{ simulation.invoice.discount | points }}%
                </div>
              </div>

              <div class="row justify-content-center text-center">
                <div class="col-6 col-md-3 mb-3">
                  <span
                    class="bg-soft-light rounded px-2 font-size-11 text-uppercase"
                    >Valor da Fatura</span
                  ><br />
                  {{ simulation.invoice.value | currency }}
                </div>
                <div class="col-6 col-md-3 mb-3">
                  <span
                    class="bg-soft-light rounded px-2 font-size-11 text-uppercase"
                    >Tarifa Energia</span
                  ><br />
                  {{ simulation.invoice.fee }}
                </div>
                <div class="col-6 col-md-3 mb-3">
                  <span
                    class="bg-soft-light rounded px-2 font-size-11 text-uppercase"
                    >Consumo em KWh</span
                  ><br />
                  {{ simulation.invoice.consumption }} KWh
                </div>
                <div class="col-6 col-md-3 mb-3">
                  <span
                    class="bg-soft-light rounded px-2 font-size-11 text-uppercase"
                    >Média de Consumo</span
                  ><br />
                  {{ simulation.invoice.average_consumption }} KWh
                </div>
              </div>

              <div class="divisor mt-5 mb-3">
                <span>Dados de KW Por Mês</span>
              </div>

              <div class="row justify-content-center text-center mb-1">
                <div class="col-3 col-md-2">
                  <span
                    class="bg-soft-light rounded px-2 font-size-11 text-uppercase"
                    >1º</span
                  ><br />
                  {{ simulation.invoice.consumption_month_1 }} KWh
                </div>
                <div class="col-3 col-md-2">
                  <span
                    class="bg-soft-light rounded px-2 font-size-11 text-uppercase"
                    >2º</span
                  ><br />
                  {{ simulation.invoice.consumption_month_2 }} KWh
                </div>
                <div class="col-3 col-md-2">
                  <span
                    class="bg-soft-light rounded px-2 font-size-11 text-uppercase"
                    >3º</span
                  ><br />
                  {{ simulation.invoice.consumption_month_3 }} KWh
                </div>
                <div class="col-3 col-md-2">
                  <span
                    class="bg-soft-light rounded px-2 font-size-11 text-uppercase"
                    >4º</span
                  ><br />
                  {{ simulation.invoice.consumption_month_4 }} KWh
                </div>
              </div>

              <div class="row justify-content-center text-center mb-1">
                <div class="col-3 col-md-2">
                  <span
                    class="bg-soft-light rounded px-2 font-size-11 text-uppercase"
                    >5º</span
                  ><br />
                  {{ simulation.invoice.consumption_month_5 }} KWh
                </div>
                <div class="col-3 col-md-2">
                  <span
                    class="bg-soft-light rounded px-2 font-size-11 text-uppercase"
                    >6º</span
                  ><br />
                  {{ simulation.invoice.consumption_month_6 }} KWh
                </div>
                <div class="col-3 col-md-2">
                  <span
                    class="bg-soft-light rounded px-2 font-size-11 text-uppercase"
                    >7º</span
                  ><br />
                  {{ simulation.invoice.consumption_month_7 }} KWh
                </div>
                <div class="col-3 col-md-2">
                  <span
                    class="bg-soft-light rounded px-2 font-size-11 text-uppercase"
                    >8º</span
                  ><br />
                  {{ simulation.invoice.consumption_month_8 }} KWh
                </div>
              </div>

              <div class="row justify-content-center text-center mb-3">
                <div class="col-3 col-md-2">
                  <span
                    class="bg-soft-light rounded px-2 font-size-11 text-uppercase"
                    >9º</span
                  ><br />
                  {{ simulation.invoice.consumption_month_9 }} KWh
                </div>
                <div class="col-3 col-md-2">
                  <span
                    class="bg-soft-light rounded px-2 font-size-11 text-uppercase"
                    >10º</span
                  ><br />
                  {{ simulation.invoice.consumption_month_10 }} KWh
                </div>
                <div class="col-3 col-md-2">
                  <span
                    class="bg-soft-light rounded px-2 font-size-11 text-uppercase"
                    >11º</span
                  ><br />
                  {{ simulation.invoice.consumption_month_11 }} KWh
                </div>
                <div class="col-3 col-md-2">
                  <span
                    class="bg-soft-light rounded px-2 font-size-11 text-uppercase"
                    >12º</span
                  ><br />
                  {{ simulation.invoice.consumption_month_12 }} KWh
                </div>
              </div>

              <div class="divisor mt-5 mb-3">
                <span>Simulação de Economia</span>
              </div>

              <div class="row">
                <div class="col-md-6">
                  <div class="card">
                    <div class="card-body bg-soft-light rounded">
                      <h5 class="mb-4 text-center">Comparativo de Economia</h5>
                      <div class="row">
                        <div class="col-6 text-center">
                          <div class="compare align-items-end">
                            <div class="last">{{ simulation.invoice.average_value | currency }}</div>
                          </div>
                          <div class="mt-2 font-size-12" style="line-height: 14px;">Média últimos 12 meses</div>
                        </div>
                        <div class="col-6 text-center">
                          <div class="compare align-items-end">
                            <div class="economy">{{ simulation.invoice.value_final | currency }}</div>
                          </div>
                          <div class="mt-2 font-size-12" style="line-height: 14px;">Fatura com Economia</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="card">
                    <div class="card-body bg-soft-light rounded">
                      <h5 class="mb-4 text-center">Escala de Economia</h5>
                      <ul class="scale">
                        <li>
                          Economia <strong>Mensal</strong><br>
                          <h4 class="my-2 text-success">{{ simulation.invoice.savings_value | currency }}</h4>
                        </li>
                        <li>
                          Economia <strong>1 Ano</strong><br>
                          <h4 class="my-2 text-success">{{ simulation.invoice.savings_value * 12 | currency }}</h4>
                        </li>
                        <li>
                          Economia <strong>3 Anos</strong><br>
                          <h4 class="my-2 text-success">{{ simulation.invoice.savings_value * 36 | currency }}</h4>
                        </li>
                        <li>
                          Economia <strong>5 Anos</strong><br>
                          <h4 class="my-2 text-success">{{ simulation.invoice.savings_value * 60 | currency }}</h4>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              <div v-if="simulation.invoice.status && simulation.invoice.status == 'pending'" class="text-center my-4">
                <button class="btn btn-default btn-lg" @click="$bvModal.show('confirmContract')">
                  INICIAR CONTRATO
                </button>

                <b-modal id="confirmContract" hide-footer centered>
                  <template v-slot:modal-title>
                    Confirmar
                  </template>
                  <div class="p-3">
                    <div class="d-block text-center">
                      <p>Você tem certeza que deseja gerar o contrato?</p>
                    </div>
                    <div class="d-flex text-center">
                      <button class="btn btn-default btn-lg btn-block m-3" v-on:click="createContract(); $bvModal.hide('confirmContract')" :disabled="loading">
                        SIM
                        <b-spinner
                          v-if="loading"
                          small
                          class="ml-2 align-middle"
                          variant="white"
                          role="status"
                        ></b-spinner>
                      </button>

                      <button class="btn btn-danger btn-lg btn-block m-3" @click="$bvModal.hide('confirmContract')">
                        NÃO
                      </button>
                    </div>
                  </div>
                </b-modal>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<style scoped>
.label-description {
  margin: -10px 0 10px 0;
}
.divisor {
  position: relative;
  text-align: center;
  text-transform: uppercase;
}
.divisor span {
  position: relative;
  display: inline-block;
  padding: 0 5px;
  background: #fff;
  font-size: 10px;
  letter-spacing: 2px;
  color: #888;
}
.divisor:before {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  top: 52%;
  height: 1px;
  background: #ccc;
}

.compare {
  display: flex;
  background: #d1d6d9;
  border-radius: 10px;
  height: 280px;
  color: #fff;
}
.compare div {
  border-radius: 0 0 10px 10px;
  padding: 15px 0;
  width: 100%;
  font-size: 18px;
}
.compare div.last {
  background: #052e53;
  height: 80%;
}
.compare div.economy {
  background: #16ad16;
  height: 55%;
}

ul.scale li {
  position:relative;
  margin-bottom: 0;
  padding-bottom: 10px;
}

ul.scale li:after {
  content: "\2022";
  position: absolute;
  left: -23px;
  top: -20px;
  font-size: 40px;
  color: #052E53;
}

ul.scale li:before {
  content:"";
  position: absolute;
  left: -16px;
  border-left: 2px solid #052E53;
  height: 100%;
  width: 1px;
}

ul.scale li:first-child:before {
  top: 6px;
}

ul.scale li:last-child:before {
  height: 6px;
}
</style>
